<template>
    <div>
        <div class="card mt-3">
            <div class="card-body">
                <new-campaign @created="newItemCreated()" class="mb-0"/>
            </div>
        </div>

        <div class="card mt-3">
            <div class="card-body">
                <div class="row mt-2">
                    <div class="col-lg-6 col-md-6">
                        <div class="input-group">
                            <input class="form-control" type="text" v-model="form.search" placeholder="Search campaign by a name">
                            <div class="input-group-append">
                                <button class="btn btn-outline-input" type="button" @click=" form.search = '' ">X</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <div class="form-group">
                            <select class="form-control" v-model="form.orderBy">
                                <option value="links_aggregate.aggregate.count">By links count</option>
                                <option value="tags_aggregate.aggregate.count">By tags count</option>
                                <option value="stats_total.0.users">By users</option>
                                <option value="stats_total.0.hits">By hits</option>
                                <option value="name">By name</option>
                                <option value="created_at">By date</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-8">
                        <div class="form-group">
                            <date-range-form :range="$store.state.campaign.dates" @input="form.dates = $event" :disabled="true"/>
                        </div>
                    </div>
                    <div class="col-lg-1 col-md-4">
                        <div class="form-group text-right">
                            <button class="btn btn-outline-input btn-block" @click="data()" v-if="!loading"><i class="mdi mdi-reload"></i></button>
                            <button class="btn btn-outline-input btn-block" type="button" disabled v-else>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="sr-only"></span>
                            </button>
                        </div>
                    </div>
                </div>

                <transition-group  name="flip-list" tag="div" class="row" v-if="orderedData && orderedData.length">
                    <div class="col-xl-6" v-for="(item, i) in orderedData" :key="item.id">
                        <div class="card widget-flat" >
                            <div class="card-body">
                                <div class="float-right">
                                    <button class="btn btn-xs btn-outline-secondary mr-1" @click="$refs.edit_campaign.openModal(item)">
                                        <span class="d-md-none"><i class="mdi mdi-pencil"></i></span>
                                        <span class="d-none d-md-block">Edit</span>
                                    </button>
                                    <router-link :to="{ name: 'Links', params: { campaign: item.id } }" class="btn btn-xs mr-1 btn-outline-secondary">
                                        <span class="d-md-none"><i class="mdi mdi-google-analytics"></i></span>
                                        <span class="d-none d-md-block">Links</span>
                                    </router-link>
                                    <router-link :to="{ name: 'Dashboard', params: { campaign: item.id } }" class="btn btn-xs btn-outline-secondary">
                                        <span class="d-md-none"><i class="mdi mdi-google-analytics"></i></span>
                                        <span class="d-none d-md-block">Dashboard</span>
                                    </router-link>
                                </div>
                                <h4 class="text-uppercase mb-3 mt-0" title="Revenue">
                                    <router-link :to="{ name: 'Dashboard', params: { campaign: item.id } }" class="text-secondary">
                                        {{ item.name }}
                                    </router-link>
                                </h4>
                                <h4 class="mb-0">
                                    <router-link :to="{ name: 'Links', params: { campaign: item.id } }" class="text-secondary">
                                        <span class="h5">links </span> {{ item.links_aggregate.aggregate.count || 0 }}
                                    </router-link>
                                    <router-link :to="{ name: 'Tags', params: { campaign: item.id } }" class="text-secondary">
                                        <span class="ml-2 h5">tags </span> {{ item.tags_aggregate.aggregate.count || 0 }}
                                    </router-link>
                                    <span class="ml-3 h5 text-muted">users </span> {{ formatAggregate(item, 'stats_total', '0', 'users') }}
                                    <span class="ml-2 h5 text-muted">hits </span> {{ formatAggregate(item, 'stats_total', '0', 'hits') }}
                                </h4>

                                <div v-if="item.stats_byhour && item.stats_byhour.length" class="mt-3" style="margin-left:-1.5rem; margin-right:-1.5rem; margin-bottom:-1.5rem">
                                    <spark-bar-chart :chartData="chartData(item)" style="height: 120px;"/>
                                </div>
                                <p v-else class="mt-3 mb-0">No stats for given period</p>
                            </div>
                        </div>
                    </div>
                </transition-group>
                <div class="card border-secondary border mb-0" v-else>
                    <div class="card-body">
                        <h5 class="card-title">No campaigns are created yet</h5>
                        <p class="card-text">Type in a campaign name in the form above. The campaign will contain its links, tags and statistics.</p>
                    </div>
                </div>

            </div>
        </div>
        

        <edit-campaign-modal ref="edit_campaign" @update:form="editItem($event)" @remove:form="removeItem($event)"/>
    </div>
</template>

<script>
import SparkBarChart from '@/components/charts/SparkBar'
import NewCampaign from '@/components/campaign/NewCampaignForm'
import EditCampaignModal from '@/components/modal/EditCampaign.vue'
import DateRangeForm from '@/components/form/DateRange.vue'

import dayjs from '@/plugins/day'
import { getNested } from '@/plugins/utils'

import { getCampaignsWithStats } from '@/plugins/hasura/queries'

export default {
    name: 'Links',
    data: () => {
        return {
            loading: false,
            REDIRECT_DOMAIN: process.env.VUE_APP_REDIRECT_URL,
            form: {
                search: '',
                orderBy: 'links_aggregate.aggregate.count'
            }
        }
    },
    computed: {
        filteredData() {
            if(!this.form.search) return this.$store.state.campaign.items

            let search = this.form.search.toLowerCase()
            return this.$store.state.campaign.items.filter(el => {
                return  el.name && el.name.toLowerCase().indexOf(search) !== -1
            })
        },
        orderedData() {
            let data = this.filteredData || []
            let path = this.form.orderBy.split('.')

            data.sort((a, b) => {
                let a_value = getNested(a, ...path) || null
                let b_value = getNested(b, ...path) || null

                if(b_value == a_value) return 0
                return b_value > a_value ? 1 : -1
            })

            return data
        }
    },
    async beforeMount() {
		await this.data()
    },
    methods: {
        async data() {
            this.loading = true
            await this.$store.dispatch('campaign/get_items')
            this.loading = false
        },
        async editItem(data = {}) {
            //todo get only one item
            this.data()
        },
        async newItemCreated() {
            await this.$store.dispatch('campaign/get_items')
            this.form.orderBy = 'created_at'
        },
        format_date(date) {
			return dayjs(date).format('DD.MM HH:mm');
        },
        formatAggregate(item, ...path) {
            return getNested(item, ...path) || 0
        },
        chartData(item) {
            let     startDate = this.$store.state.campaign.dates.startDate,
                    endDate = this.$store.state.campaign.dates.endDate,
                    now = dayjs()

            let stats = {}
            item.stats_byhour.forEach((el) => {
                stats[ dayjs(el.hour).toString() ] = el.users
            })
        
            let toStats = []
            for (let date = dayjs(startDate); date.isBefore(now); date = date.add(1, 'hour')) {
                let dateString = date.toString()

                toStats.push({
                    x: dateString,
                    y: stats[dateString] ? stats[dateString] : 0
                })
            }

            return {
                datasets: [
                    {
                        minBarLength: 1,
                        barPercentage: 1,
                        categoryPercentage: 1.08,
                        label: 'Users',
                        maxBarThickness: 16,
                        backgroundColor: '#56aee2',
                        data: toStats
                    }
                ]
            }
        },
    },
    watch: {
        'form.dates'() {
            if(this.form.dates.endDate.toString() == this.form.dates.startDate.toString()) {
                this.form.dates.endDate = dayjs(this.form.dates.endDate).add(1, 'day')
            }
            //this.data()
        }
    },
    components: {
        NewCampaign,
        EditCampaignModal,
        DateRangeForm,
        SparkBarChart
    }
}
</script>

<style>
.link-card {
    margin-bottom: 5px;
    border-bottom: 2px solid rgba(154,161,171,.15);
}
.link-card .card-body {
    padding: 1rem 1.5rem;
}
</style>