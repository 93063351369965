<template>
	<div :id="hashtag" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-body">
					<div class="form-group">
						<label>Campaign name </label>
						<input class="form-control" type="text" placeholder="Enter a name" v-model="form.name" @keyup.enter="submitForm()" />
					</div>
				</div>
				<div class="modal-footer">
					<div>
						<button type="button" class="btn btn-light" data-dismiss="modal">Close</button>

						<button v-if="!loading" class="btn btn-primary" type="submit" @click.prevent="submitForm()">
							Submit
						</button>
						<button v-else class="btn btn-primary" type="button" disabled>
							<span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
							Loading...
						</button>
					</div>
				</div>
			</div>
			<!-- /.modal-content -->
		</div>
		<!-- /.modal-dialog -->
	</div>
	<!-- /.modal -->
</template>

<script>
export default {
    name: 'EditCampaignModal',
	data: () => {
		return {
			hashtag: 'edit-campaign-modal',
			cForm: {
				id: null,
				name: ''
			},
			form: {},
			loading: false
		};
	},
	mounted() {
		this.resetForm();
	},
	methods: {
        async openModal(data) {
			console.log('openModal', data)

			this.form.id = data.id 
			this.form.name = data.name 

            $(`#${this.hashtag}`).modal('show');
        },
        closeModal() {
			$(`#${this.hashtag}`).modal('hide');
		},
		resetForm() {
			this.form = Object.assign({}, this.cForm);
		},
		async submitForm() {
			if (this.loading) return $.NotificationApp.send('Error', 'Please wait', 'top-right', '', 'error');

			if (!this.form.name)
				return $.NotificationApp.send('Error', 'Please enter a name', 'top-right', '', 'error');

			this.loading = true;
			
			//editing base
			var [err, result] = await this.$orm.mutate({
                    campaign: {
                        update: {
                            where: { 
								id: { _eq: this.form.id }
							},
                            _set: { 
								name: this.form.name,
							}
                        }
                    }
                })

			if (err) {
				this.loading = false;
				console.error('edit-campaign-modal', 'methods.submitForm', '$http.request1', err);
				return $.NotificationApp.send('Error', err.message, 'top-right', '', 'error');
			}

			this.loading = false;
			this.$emit('update:form', this.form)

			this.resetForm();
            this.closeModal();
		}
	},
};
</script>